import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import swal from 'sweetalert'
import { useState } from "react";

import googled from "../assets/google.png";
export function Form() {
  return (
    <Card color="transparent" shadow={false}>
      <form className="mt-4 mb-2 w-80 max-w-screen-lg sm:w-96">
        <div className="mb-4 flex flex-col gap-6">
          <Input size="lg" label="Name" />
          <Input size="lg" label="Email" />
          <Input type="password" size="lg" label="Password" />
        </div>
        <Checkbox
          label={
            <Typography
              variant="small"
              color="gray"
              className="flex items-center font-normal"
            >
              J'accepte
              <a
                href="#"
                className="font-medium transition-colors hover:text-blue-500"
              >
                &nbsp;les termes et les conditions.
              </a>
            </Typography>
          }
          containerProps={{ className: "-ml-2.5" }}
        />
        <Button className="mt-6" fullWidth>
          Register
        </Button>
        <Button variant="outlined" color="blue-gray" className="mt-2" fullWidth>
          <div className="flex flex-row items-center justify-center">
            <img src={googled} className="h-6 w-6 mr-4" />
            Continue with Google
          </div>
        </Button>
        <Typography color="gray" className="mt-4 text-center font-normal">
          Already have an account?{" "}
          <a
            href="#"
            className="font-medium text-blue-500 transition-colors hover:text-blue-700"
          >
            Sign In
          </a>
        </Typography>
      </form>
    </Card>
  );
}
export function FormEmail({data}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const EnvoyeEmail = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      console.error('Name and email are required');
      return;
    }
    try {
      const response = await fetch('http://fit-san.com/api/sendMail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      });

      if (response.ok) {
        swal({
          title: "Good job!",
          text: "Email envoyé !",
          icon: "success"
        });
        } else {
          swal({
            title: "Oops!",
            text: "Il y'a eu un probléme !",
            icon: "error"
          });      }
    } catch (error) {
      console.error('Error sending email:', error.message);
    }
  };

  return (
    <Card color="transparent" shadow={false}>
      {console.log(data)}
      <form onSubmit={EnvoyeEmail} className="mt-4 mb-2 w-80 max-w-screen-lg sm:w-96">
        <div className="mb-4 flex flex-col gap-6">
          <Input
            size="lg"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="lg"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Button type="submit" className="mt-6" fullWidth>
          Envoyer
        </Button>
      </form>
    </Card>
  );
}
