import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import {Modal} from "./Modal";
import ScrollReveal from 'scrollreveal';
import "../css/Tout.css";
import logo from "../assets/logodeux.png";
import { Link } from "react-router-dom";
export default function Header() {
  const [openNav, setOpenNav] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  useEffect(() => {
    ScrollReveal({
      reset: false,
      distance: "100px",
      delay: 200,
      duration: 500,
    });
    ScrollReveal().reveal(".hedd", { delay: 500, origin: "top" });
  }, [])
  
  const onClick = () => {
    window.location.href = "#moi";
  };

  const onClick2 = () => {
    window.location.href = "#service";
  };
  const onClick3 = () => {
    window.location.href = "#coute";
  };

  const navList = (
    <ul className="mb-4 mt-2 px-10 lg:bg-white bg-white lg:py-2 shadow flex flex-col  lg:border-2 border-2 lg:rounded-full rounded-lg  lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-2">
      <Typography
        as="li"
        variant="medium"
        color="gray"
        className="list_menu lg:p-2 lg:ml-2"
      >
        <Link href="#" className="list_menu text-black flex items-center">
          ACCEUIL
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="medium"
        color="gray"
        className="list_menu lg:p-2"
      >
        <Link
          href="#moi"
          onClick={onClick}
          className="list_menu flex text-black items-center"
        >
          QUI SUIS-JE ?
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="medium"
        color="gray"
        className="list_menu lg:p-2"
      >
        <Link
          href="#service"
          onClick={onClick2}
          className="list_menu flex text-black items-center"
        >
          SERVICES
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="medium"
        color="gray"
        className="list_menu lg:p-2 lg:mr-2"
      >
        <Link
          href="#coute"
          onClick={onClick3}
          className="list_menu flex text-black items-center"
        >
          TARIFS
        </Link>
      </Typography>
    </ul>
  );

  return (
    <Navbar
      variant="gradient"
      color="transparent"
      className="mx-auto max-w-screen-2xl lg:px-8 lg:py-4"
    >
      <div className="mx-auto flex items-center justify-between text-white-900">
        <img src={logo} alt="" className="hedd w-[150px] h-[75px] lg:w-[200px] lg:h-[100px]" />
        <div className="hidden hedd lg:block mr-10">{navList}</div>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="hedd text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-full  text-sm px-5 py-2.5 text-center mr-2 mb-2 hidden lg:inline-block"
        >
          S'INSCRIRE
        </button>
        <Modal showModal={showModal} setShowModal={setShowModal} />
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav open={openNav}>
        <div className="container mx-auto">
          {navList}
          <Button
            variant="gradient"
            onClick={() => setShowModal(true)}
            size="sm"
            fullWidth
            className="mb-2"
          >
            <span>S'INSCRIRE</span>
          </Button>
        </div>
      </MobileNav>
    </Navbar>
  );
}
