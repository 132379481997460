import React from "react";
import Header from "../Component/Header";
import "../css/Tout.css";
import Content from "./PremierePage/Content";
import ContentDeux from "./DeuxiemPage/ContentDeux";
import ContentTrois from "./TroisiemePartie/ContentTrois";
import { Footer } from "../Component/Footer";
import { ContentQuatre } from "./QuatriemPage/ContentQuatre";
import { GiMeal } from "react-icons/gi";
import { MdFitnessCenter } from "react-icons/md";
import { GiBodySwapping } from "react-icons/gi";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../assets/moi.jpeg";
export default function Acceuil() {
  const element = [
    {
      img: <GiBodySwapping />,
      titre: "Entraînement personnel",
      paragraphe:
        "Entraînement Personnel propose une approche personnalisée et novatrice, guidant chaque individu vers ses objectifs avec expertise et motivation.",
    },
    {
      img: <MdFitnessCenter />,
      titre: "plan d'entraînement",
      paragraphe:
        "Un plan d'entraînement détaille les exercices, la fréquence et l'intensité pour atteindre des objectifs spécifiques en matière de condition physique.",
    },
    {
      img: <GiMeal />,
      titre: "plan de repas",
      paragraphe:
        "Un plan de repas guide les choix alimentaires pour atteindre des objectifs particuliers en matière de santé, de poids ou de condition physique.",
    },
  ];
  const cardPrice = [
    {
      id:1,
      titre: "Découverte",

      Prix: "500",
      currency: "Dh",
      caracteristique: [
        "1 Programme nutritif",
        "Un programme d'entrainement",
        "Durée: Une semaine",
        "Ajustements hebdomadaire",
      ],
    },
    {
      id:2,
      titre: "Standart",

      Prix: "750",
      currency: "Dh",
      caracteristique: [
        "2 Programmes nutritifs",
        "Un programme d'entrainement",
        "Durée: Un mois",
        "Ajustement hebdomadaire",
      ],
    },
    {
      id:3,
      titre: "Premium",

      Prix: "1000",
      currency: "Dh",
      caracteristique: [
        "6 Programmes nutritifs",
        "Un programme d'entrainement",
        "Durée: Un mois",
        "Ajustement hebdomadaire",
      ],
    },
    {
      id:4,
      titre: "Personaliser",

      Prix: "2000",
      currency: "Dh",
      caracteristique: [
        "10 Programmes nutritifs",
        "Un programme d'entrainement",
        "Durée: Trois mois",
        "Ajustement hebdomadaire",
      ],
    },
  ];
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+212655243799"
        accountName="Elabd Iliass"
        allowEsc
        allowClickAway
        chatMessage="Que puis-je faire pour vous"
        darkMode={true}
        avatar={logo}
      />
      <div className="premierdiv h-screen">
        <Header />
        <Content />
      </div>
      <div
        id="moi"
        className="grid grid-cols-12 max-w-screen-xl mx-auto py-28 px-5"
      >
        <ContentDeux />
      </div>
      <div className="troisiemdiv lg:h-screen h-fit lg:flex lg:flex-col lg:items-center lg:justify-center">
        <ContentTrois element={element} />
      </div>
      <div className="quatriemdiv">
        <ContentQuatre cardPrice={cardPrice} />
      </div>

      <Footer />
    </div>
  );
}
