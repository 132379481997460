import React from "react";
import imageun from "../../assets/damn.png";
import ScrollReveal from "scrollreveal";
import { useEffect } from "react";
import { Typography, Button } from "@material-tailwind/react";
import "../../css/Tout.css";
export default function Content() {
  useEffect(() => {
    ScrollReveal({
      reset: false,
      distance: "100px",
      delay: 200,
      duration: 500,
    });
    ScrollReveal().reveal(".ecriture", { delay: 500, origin: "left" });
    ScrollReveal().reveal(".im", { delay: 500 });
  }, []);
  return (
    <div className="grid grid-cols-12 max-w-screen-lg mx-10 lg:mx-auto">
      <div className="ecriture h-[300px] col-span-12 lg:col-span-6 my-auto">
        <Typography className="ecrit font-bold text-white lg:pt-5 my-auto overline mx-auto mt-20 w-fit  lg:mt-0 lg:ml-0 text-4xl md:text-6xl lg:text-6xl">
          <span class="uppercase block pb-5 lg:pb-10">Où le fitness </span>{" "}
          <span class="uppercase block pb-5 lg:pb-10 ">devient votre</span>
          <span class="uppercase block pb-5 lg:pb-10"> style de vie</span>
          <Button className="rounded-full w-40">LET'S START</Button>
        </Typography>
      </div>
      <div className="im hidden lg:block lg:col-span-6">
        <img src={imageun} alt="" className="xl:ml-10" />
      </div>
    </div>
  );
}
