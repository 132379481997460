import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import {ModalEmail} from "../../Component/Modal";
import { useState, useEffect } from "react";
import ScrollReveal from "scrollreveal";
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export function ContentQuatre({ cardPrice }) {
  const [modalEmail, setModalEmail] = useState({show: false, data: null});
  useEffect(() => {
    ScrollReveal({
      reset: false,
      distance: "100px",
      delay: 500,
      duration: 500,
    });
    ScrollReveal().reveal(".titre_tarif", { delay: 500, origin: "top" });
    cardPrice.forEach((a, index) => {
      ScrollReveal().reveal(`.divelementQuatre:nth-child(${index + 1})`, {
        delay: 500 + 200 * index,
        origin: "top",
      });
    });
  }, [cardPrice]);
  return (
    <>
      <ModalEmail showModalEmail={modalEmail.show} data={modalEmail.data} setModalEmail={setModalEmail} />

      <h1 className="titre_tarif" id="coute">
        Combien ça coute ?
      </h1>
      <div className="quatriemcont container mx-auto mt-20 flex flex-row flex-wrap justify-center lg:justify-between">
        {cardPrice.map((i, index) => {
          return (
            <Card
              key={index}
              variant="gradient"
              className="divelementQuatre w-full max-w-[17rem] p-8 m-5 lg:mb-0 "
            >
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className=" m-8 rounded-none border-b border-white/10 pb-8 text-center"
              >
                <Typography
                  variant="small"
                  color="black"
                  className="font-normal uppercase"
                >
                  {i.titre}
                </Typography>
                <Typography
                  variant="h1"
                  color="black"
                  className="mt-6 flex justify-center gap-1 text-5xl font-normal"
                >
                  {i.Prix}{" "}
                  <span className="self-end text-lg">{i.currency}</span>
                </Typography>
              </CardHeader>
              <CardBody className="p-0 flex">
                <ul className="flex flex-col gap-2">
                  {i.caracteristique.map((c, index) => {
                    const isTargetValue = c.includes(
                      "Ajustements hebdomadaire"
                    );

                    return (
                      <li key={index} className="flex items-center gap-4">
                        <span className="rounded-full border border-white/20 bg-white/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography
                          className={`font-normal ${
                            isTargetValue ? "line-through" : ""
                          }`}
                        >
                          {c}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </CardBody>
              <CardFooter className="mt-12 p-0">
                <Button
                  size="lg"
                  className="text-white hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                  ripple={false}
                  fullWidth={true}
                  onClick={() => setModalEmail({show: true, data: i})}
                  // onClick={()=>console.log(i.id)}
                >
                  Buy Now
                </Button>
              </CardFooter>
            </Card>
          );
        })}
      </div>
    </>
  );
}
