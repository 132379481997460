import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import ScrollReveal from 'scrollreveal';
import { useEffect } from "react";
import "../../css/Tout.css";
import { ModalService } from "../../Component/Modal";

export default function ContentTrois({ element }) {
  useEffect(() => {
    ScrollReveal({
      reset: false,
      distance: "100px",
      delay: 500,
      duration: 500,
    });
    ScrollReveal().reveal(".ounwan", { delay: 500, origin: "top" });
    element.forEach((a, index) => {
      ScrollReveal().reveal(`.divelement:nth-child(${index + 1})`, {
        delay: 500 + 200 * index,
        origin: "top",
      });
    });
  }, [element]);

  // Change the state to track the index of the element for which the modal is open
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  return (
    <>
      <h1
        className="ounwan titre_service py-20 lg:-mt-32 drop-shadow-xl text-white font-extrabold text-4xl text-center"
        id="service"
      >
        LES SERVICES DISPONIBLE :
      </h1>
      <div className="troisiemcontent mx-auto w-6/7 flex flex-row justify-between">
        {element.map((a, index) => {
          return (
           <>         
           {modalOpenIndex === index && <ModalService typee={index} modalOpenIndex={modalOpenIndex}  setModalOpenIndex={setModalOpenIndex} />}
              <div key={index} className="divelement text-center py-5 flex flex-wrap justify-center mx-5">
              <div className="flex justify-center text-5xl pb-2 items-center w-full mx-auto">{a.img}</div>
              <p className="text-xl text-center capitalize font-bold">{a.titre}</p>
              <p className="py-2 text-justify">{a.paragraphe}</p>
              <Button onClick={() => {setModalOpenIndex(index)}} className="mt-2 rounded-full text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium text-sm px-5 py-2.5 text-center mr-2 mb-2 w-40">
                Voir
              </Button>
            </div>
            </>
          );
        })}
      </div>
    </>
  );
}