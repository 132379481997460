import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import "../../css/Tout.css";
import image2 from "../../assets/image2.png";
import ScrollReveal from 'scrollreveal';
import { useEffect } from "react";
export default function ContentDeux() {
  useEffect(() => {
    ScrollReveal({
      reset: false,
      distance: "100px",
      delay: 200,
      duration: 500,
    });
    ScrollReveal().reveal(".tswira", { delay: 500, origin: "left" });
    ScrollReveal().reveal(".ktba", { delay: 500, origin: "top" });
  },[]);
  return (
    <>
      <img src={image2} alt="" className="tswira mx-auto lg:w-[350px] my-auto col-span-12 lg:col-span-4" />
      <div className="ktba col-span-12 text-center lg:text-left  lg:col-span-8">
        <Typography className="text-5xl leading-snug	overline pt-20 pb-3 lg:py-5">QUI SUIS-JE ?</Typography>
        <Typography className="text-justify">
          Ma passion pour le sport et la nutrition a toujours été au cœur de ma
          vie, nourrissant mon désir incessant de promouvoir un mode de vie sain
          et équilibré. En tant que jeune homme franco-marocain de 21 ans, je
          suis profondément investi dans mon projet baptisé FIT-SAN, une
          initiative qui vise à inspirer et à aider les autres à atteindre leurs
          objectifs de bien-être physique et mental. Pour moi, le sport n'est
          pas seulement une activité physique, mais un véritable art de vivre.
          Je suis convaincu que l'exercice régulier et une alimentation
          équilibrée sont les clés du bien-être et de la santé optimale. À
          travers FIT-SAN, je m'efforce de partager mes connaissances en matière
          de nutrition, de proposer des plans d'entraînement personnalisés et
          d'encourager chacun à adopter de saines habitudes de vie. Mon objectif
          ultime est d'inspirer et d'accompagner un maximum de personnes dans
          leur quête d'une vie épanouie, énergique et résolument en forme.
        </Typography>
      </div>
    </>
  );
}
