import { BrowserRouter, Routes, Route } from "react-router-dom";
import Acceuil from "./Page/Acceuil";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Acceuil />} />
        <Route path="*" element={<Acceuil />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
